







































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Input, Checkbox } from '@h3/antd-vue';

@Component({
  name: 'SelectModal',
  components: {
    AInputSearch: Input.Search,
    ACheckbox: Checkbox,
    AppImage: () => import('./app-image.vue'),
  },
})
export default class SelectModal extends Vue {
  @Prop() value!: boolean;

  @Prop({ default: () => [] }) appList!: any;

  @Prop({ default: () => [] }) selectedAppList!: any;

  appListCopy = [];

  keyword = '';

  selectedAppListCopy = [] as any;

  cloneSelectedAppList = [] as any;

  visible: boolean = false;

  submiting: boolean = false;

  get baseUserId () {
    let publicUserInfo: any = localStorage.getItem('publicUserInfo') || '';
    try {
      publicUserInfo = JSON.parse(publicUserInfo);
      return publicUserInfo?.baseUserId || '';
    } catch (error) {
      return '';
    }
  }

  get logoStyle () {
    return {
      width: '32px',
      height: '32px',
      fontSize: '20px',
    };
  }

  @Watch('value')
  onChangeValue (v: boolean) {
    if (v) {
      this.appListCopy = JSON.parse(JSON.stringify(this.appList));
      this.selectedAppListCopy = JSON.parse(
        JSON.stringify(this.selectedAppList),
      );
      this.selectedAppListCopy.forEach((selectApp: any) => {
        this.appListCopy.some((app: any) => {
          if (app.appCode === selectApp.appCode) {
            app.isSelected = true;
            return true;
          }
        });
      });
      this.cloneSelectedAppList = JSON.parse(JSON.stringify(this.appListCopy));
    }
    this.visible = v;
  }

  selectChange ( // TODO 保存最后一步操作之前的状态
    index: number,
    appCode: string,
    fromParent?: boolean,
    evt?: Event,
  ) {
    const app = this.appListCopy[index] as any;
    const selectedAppListCopy = this.selectedAppListCopy;
    if (evt) {
      evt.stopPropagation();
    }
    if (app.isSelected) {
      this.selectedAppListCopy = selectedAppListCopy.filter(
        (selectedApp: any) => selectedApp.appCode !== appCode,
      );
    } else {
      this.selectedAppListCopy.push(app);
    }
    if (fromParent) {
      app.isSelected = !app.isSelected;
    } // TODO data change, view not reactive
    const cloneData = JSON.parse(JSON.stringify(this.appListCopy));
    this.appListCopy = cloneData;
    this.cloneSelectedAppList = cloneData;
  }

  searchApp () {
    if (!this.keyword || !this.keyword.trim()) {
      this.appListCopy = this.cloneSelectedAppList;
    }
    const apps = this.appListCopy.filter((app: any) =>
      app.appName.includes(this.keyword),
    );
    this.appListCopy = apps;
  }

  clearChange (index, appCode) {
    this.selectedAppListCopy = this.selectedAppListCopy.filter(
      (selectedApp: any) => selectedApp.appCode !== appCode,
    );
    this.appListCopy.forEach((app: any) => {
      if (app.appCode === appCode) {
        app.isSelected = false;
      }
      return app;
    });
  }

  clearAll () {
    this.selectedAppListCopy = [];
    this.appListCopy.forEach((app: any) => {
      app.isSelected = false;
      return app;
    });
  }

  comfirm () {
    this.$emit('input', false);
    const appCodes = [] as any;
    this.keyword = '';
    this.selectedAppListCopy.forEach(app => {
      appCodes.push(app.appCode);
    });
    this.$emit('addUsedApp', appCodes);
  }

  cancel () {
    this.selectedAppListCopy = [];
    this.keyword = '';
    this.appListCopy.forEach((app: any) => (app.isSelected = false));
    this.$emit('input', false);
  }
}
